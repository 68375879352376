body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: aqua;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.coupon-checker-container {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-field {
  font-size: 18px;
  padding: 10px;
  margin: 10px 0;
  width: calc(100%); /* Full width minus padding */
  box-sizing: border-box; /* Include padding in width */
  text-align: center;
}

.check-button {
  padding: 10px 20px;
  font-size: 18px;
  margin-top: 10px;
  width: 100%;
  box-sizing: border-box;
}

.message {
  font-size: 32px;
  margin-top: 10px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  color: white;
}
